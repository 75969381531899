import React, { useEffect, useState } from 'react';
import Table from './components/Table';
import { Connection, PublicKey, clusterApiUrl } from '@solana/web3.js';
import { Program, Provider, web3 } from '@project-serum/anchor';

import idl from './idl.json';
import kp from './keypair.json'

// SystemProgram is a reference to the Solana runtime!
const { SystemProgram } = web3;

// Create a keypair for the account that will hold the Blame data.
const arr = Object.values(kp._keypair.secretKey)
const secret = new Uint8Array(arr)
const baseAccount = web3.Keypair.fromSecretKey(secret)

// Get out program's ID from the IDL file.
const programID = new PublicKey(idl.metadata.address);

// Set our network to devnet.
const network = clusterApiUrl('devnet');

// Control how we want to acknowledge when a transaction is "done".
const opts = {
  preflightCommitment: "processed"
}

function App() {
  const [walletAddress, setWalletAddress] = useState(null);
  const [blameList, setBlameList] = useState([]);
  const [blamerValue, setBlamerValue] = useState('');
  const [blameeValue, setBlameeValue] = useState('');
  const [actionValue, setActionValue] = useState('');

  window.dataLayer = window.dataLayer || [];

  const onBlamerChange = (event) => {
    const { value } = event.target;
    setBlamerValue(value);
  };
  const onBlameeChange = (event) => {
    const { value } = event.target;
    setBlameeValue(value);
  };
  const onActionChange = (event) => {
    const { value } = event.target;
    setActionValue(value);
  };

  const sendBlame = async (e) => {
    e.preventDefault();
    if (blamerValue.length === 0) {
      alert("Enter your name to blame someone.")
      return
    }
    if (blameeValue.length === 0) {
      alert("Enter the name of the person you're blaming.")
      return
    }
    if (actionValue.length === 0) {
      alert("Enter what you are blaming them for.")
      return
    }
    console.log(`Trying: ${blamerValue} blames ${blameeValue} for ${actionValue}.`)
    try {
      const provider = getProvider();
      const program = new Program(idl, programID, provider);

      await program.rpc.addBlame(blamerValue, blameeValue, actionValue, {
        accounts: {
          baseAccount: baseAccount.publicKey,
          user: walletAddress
        }
      });
      console.log(`Blame successfully sent to program: ${blamerValue} blames ${blameeValue} for ${actionValue}.`)
      // push to dataLayer
      window.dataLayer.push({
        'event': 'addBlame',
      })
      // Clear the form.
      setBlameeValue('');
      setBlamerValue('');
      setActionValue('');
      alert(`Blame successfully sent to program: ${blamerValue} blames ${blameeValue} for ${actionValue}.  Scroll to the bottom of the page to see the blame list.`)

      await getBlameList();
    } catch (e) {
      console.log("Error sending Blame: ", e)
    }
  };

  const checkIfWalletIsConnected = async () => {
    try {
      const { solana } = window;

      if (solana) {
        if (solana.isPhantom) {
          console.log('Phantom wallet found!');
          const response = await solana.connect();
          console.log(
            'Connected with Public Key:',
            response.publicKey.toString()
          );
          setWalletAddress(response.publicKey.toString());
        }
      } else {
        alert('Solana object not found! Get a Phantom Wallet 👻');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const connectWallet = async () => {
    const { solana } = window;

    if (solana) {
      const response = await solana.connect();
      console.log('Connected with Public Key:', response.publicKey.toString());
      setWalletAddress(response.publicKey.toString());
      window.dataLayer.push({
        'event': 'connectWallet',
        'publicKey': response.publicKey.toString()
      })
    }
  };

  const disconnectWallet = async () => {
    const { solana } = window;

    if (solana) {
      await solana.disconnect();
      console.log('Disconnected Public Key:', walletAddress);
      window.dataLayer.push({
        'event': 'disconnectWallet',
        'publicKey': walletAddress
      })
      setWalletAddress(null);
    }
  }

  const getProvider = () => {
    const connection = new Connection(network, opts.preflightCommitment);
    const provider = new Provider(
      connection, window.solana, opts.preflightCommitment
    );
    return provider
  };

  const createAccount = async () => {
    try {
      const provider = getProvider();
      const program = new Program(idl, programID, provider);
      console.log('ping')
      await program.rpc.initialize({
        accounts: {
          baseAccount: baseAccount.publicKey,
          user: provider.wallet.publicKey,
          systemProgram: SystemProgram.programId,
        },
        signers: [baseAccount]
      });
      console.log("Created a new BaseAccount with address: ", baseAccount.publicKey.toString());
      await getBlameList();

    } catch (e) {
      console.log("Error creating BaseAccount account: ", e)
    }
  };

  const getBlameList = async () => {
    try {
      const provider = getProvider();
      const program = new Program(idl, programID, provider);
      const account = await program.account.baseAccount.fetch(baseAccount.publicKey);

      console.log("Got the account", account);
      console.log("BLAME COUNT: ", account.totalBlames.toString());
      setBlameList(account.blameList);
    } catch (e) {
      console.log("Error in getBlames: ", e);
      setBlameList(null);
    }
  }

  const renderBlames = () => {
    // If we hit this, it means the program account hasn't been initialized.
    if (blameList === null) {
      return (
        <div className="connected-container h-screen w-screen flex">
          <button className="text-white text-2xl font-bold tracking-tighter cursor-pointer px-10 bg-red-500 py-2 rounded-lg transform hover:scale-105 m-auto" onClick={createAccount}>
            <h2>Do One-Time Initialization for Blame Program Account</h2>
          </button>
        </div>
      )
    } else {
      // console.log("Rendering Blame List: ", blameList);
      return (
        <div className='container mx-auto'>
          <div className="my-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST" id="myBlameForm">
              <div className="shadow overflow-hidden">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                        Blamer (You)
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-900 rounded-md py-2 px-3"
                        value={blamerValue}
                        onChange={onBlamerChange}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        Blamee (Them)
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-900 rounded-md py-2 px-3"
                        value={blameeValue}
                        onChange={onBlameeChange}
                      />
                    </div>

                    <div className="col-span-8 sm:col-span-8">
                      <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                        What they did
                      </label>
                      <textarea
                        rows="4"
                        name="what-they-did"
                        id="what-they-did"
                        className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-900 rounded-md py-2 px-3"
                        value={actionValue}
                        onChange={onActionChange}
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-bold rounded-md text-white bg-red-500 transform hover:scale-105 tracking-tighter cursor-pointer"
                    onClick={sendBlame}
                  >
                    save
                  </button>
                </div>
              </div>
            </form>
          </div>
          <Table blames={blameList}/>
        </div>
      )
    }
  }
  
  const renderConnect = () => {
    return (
      <div className='h-32 text-white font-black m-auto flex justify-center flex-col-reverse  md:flex-row'>
        <div className='my-auto'>
          <h2 className='my-auto text-3xl'>
            connect your wallet to pass the blame (use devnet only!)
          </h2>
          <p>Don't have a solana wallet? Download <a href="https://www.phantom.app" className='underline text-red-500'>Phantom</a>.  DM @itsjamesmurray on Twitter to get airdropped devnet SOL</p>
        </div>
        <div>
          <img
            src='arrow.png'
            className='h-full'
            style={{
              transform: 'scaleY(-1) rotate(50deg)'
            }}
            alt=''
          />
        </div>
      </div>
    )
  }

  useEffect(() => {
    const onLoad = async () => {
      await checkIfWalletIsConnected();
    };
    window.addEventListener('load', onLoad);
    return () => window.removeEventListener('load', onLoad);
  }, []);


  useEffect(() => {
    if (walletAddress) {
      console.log('Fetching Blame list...');
      getBlameList()
    }
  }, [walletAddress]);

  return (
    <div className="bg-gray-900 flex flex-col pb-10 min-h-screen">
      <header>
        <nav className="flex justify-between p-10 w-screen">
          <a
            href="/"
            className="text-white text-4xl font-bold tracking-tighter"
          >
            pass the <span className="underline text-red-500">blame</span>
          </a>
          <div>
            <button
              className="text-white text-2xl font-bold tracking-tighter cursor-pointer px-10 bg-red-500 py-2 rounded-lg transform hover:scale-105 mx-2"
              onClick={walletAddress ? disconnectWallet : connectWallet}
            >
              {walletAddress ? 'disconnect sol wallet' : 'connect sol wallet'}
            </button>
          </div>
        </nav>
      </header>
      {!walletAddress && renderConnect()}
      {walletAddress && renderBlames()}
    </div>
  );
}

export default App;

export default function Table(props) {
  return (
    <table className="divide-y divide-gray-200 mx-auto overflow-y-auto h-32 w-full">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Blamer
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Blamee
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            What they did
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {props.blames.map((blame, index) => (
          <tr key={index}>
            <td className="px-6 py-4">
              <div className="flex items-center">
                <div>
                  <div className="text-sm font-medium text-gray-900">{blame.blamerName}</div>
                  <div className="text-sm text-gray-500">{blame.blamerAddress.toString()}</div>
                </div>
              </div>
            </td>
            <td className="px-6 py-4">
              <div className="text-sm text-gray-900">{blame.blameeName}</div>
            </td>
            <td className="px-6 py-4">
              <span className="text-sm text-gray-500">
                {blame.blameAction}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
